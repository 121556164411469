<template>
  <v-dialog v-model="dialog" persistent max-width="60%" no-click-animation @click:outside="$emit('input',false)">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{$t('erp.lang_orderNo')+' '+orderNo}}</v-toolbar-title>

        <v-spacer/>

        <v-btn color="error" @click="$emit('input',false)">
          {{$t("generic.lang_close")}}
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="overflow-auto pa-0 py-1" style="height: 500px;">
        <v-container fluid v-if="!loading" class="pa-1">
          <div class="invoice-box">
            <table cellpadding="0" cellspacing="0">
              <tr class="information">
                <td colspan="4">
                  <table>
                    <tr>
                      <td>
                        <!-- CUSTOMER DATA -->
                        <div v-if="invoiceCustomerData.id > 0">
                          <span class="font-weight-bold"> {{ $t('generic.lang_billingDetails') }}:<br></span>
                          <span v-if="invoiceCustomerData.company !== null">{{
                              invoiceCustomerData.company
                            }}<br></span>
                          {{ invoiceCustomerData.first_name }}
                          {{ invoiceCustomerData.last_name }}<br>{{
                            invoiceCustomerData.street_no
                          }}<br>{{ invoiceCustomerData.zip }}
                          {{ invoiceCustomerData.city }}
                        </div>
                      </td>
                      <td>
                        <!-- CUSTOMER DATA -->
                        <div v-if="invoiceCustomerData.id > 0">
                          <span class="font-weight-bold">{{ $t('generic.lang_shippingDetails') }}:<br></span>
                          <span v-if="shippingData.company !== null">{{
                              shippingData.company
                            }}<br></span>
                          {{ shippingData.first_name }}
                          {{ shippingData.last_name }}<br>{{
                            shippingData.street_no
                          }}<br>{{ shippingData.zip }}
                          {{ shippingData.city }}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <!-- CUSTOMER DATA -->
                        <div v-if="invoiceCustomerData.id > 0">
                          <span class="font-weight-bold"> {{ $t('generic.lang_email') }}:<br></span>
                          <span v-if="invoiceCustomerData.email !== null">{{
                              invoiceCustomerData.email
                            }}<br></span>
                        </div>
                      </td>
                      <td>
                        <!-- CUSTOMER DATA -->
                        <div v-if="invoiceCustomerData.id > 0">
                          <span class="font-weight-bold">{{ $t('generic.lang_shippingMethod') }}:<br></span>
                          <span v-if="shippingType !== null && shippingType !== '0'">{{
                              shippingType
                            }}<br></span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <!-- CUSTOMER DATA -->
                        <div v-if="invoiceCustomerData.id > 0">
                          <span class="font-weight-bold">{{ $t('generic.lang_telefone') }}:<br></span>
                          <span v-if="invoiceCustomerData.telPrivate !== null">{{
                              invoiceCustomerData.telPrivate
                            }}<br></span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

            </table>

            <b-table :fields="fields" :items="computedInvoicePositions"
                     hover
                     striped>
            </b-table>
            <br>
            <span style="float:right;"><h4>{{ $t('generic.lang_subtotal') }}: {{ invoiceTotalNet | currency }}</h4></span><br
              style="clear:both;">
            <span style="float:right;"><h4>{{ $t('erp.lang_mwst') }}: {{ invoiceTotalTax | currency }}</h4></span><br
              style="clear:both;">
            <span style="float:right;"><h4
                style="font-weight:bold;">{{ $t('generic.lang_total') }}: {{ invoiceTotal | currency }}</h4></span>

            <h4>{{ $t('accounting.lang_InvoiceNotes') }}</h4>

            <br style="clear:both;">

          </div>
        </v-container>
        <v-progress-circular v-else indeterminate color="orange" class="mx-auto d-flex" size="60">

        </v-progress-circular>
      </v-card-text>
      <template v-if="deliveryTimeStamp<=0">
        <v-divider class="ma-0"/>
        <v-card-actions class="text-right">
          <v-btn-toggle
              rounded
          >
            <v-btn class="mx-0" v-if="deliveryTimeStamp===-1" @click="setStatus('processing')" :loading="loadingDtatus">
              {{ $t("delivery.lang_inProcessing") }}
            </v-btn>
            <v-btn class="mx-0" v-if="deliveryTimeStamp<=0" @click="setStatus('closed')" :loading="loadingDtatus">
              {{ $t('generic.lang_closed') }}
            </v-btn>
          </v-btn-toggle>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import {ENDPOINTS} from "../../config";
import {Events} from "../../plugins/events";

export default {
  name: "OrderOverviewComponent",
  props:{
    value:{
      type:Boolean,
    },
    uuid:{
      type:String,
    },
  },
  watch:{
    value(v){
      this.dialog=v;
      this.$emit('input',v);
    }
  },
  data(){
    return{
      ENDPOINTS,
      dialog:false,
      loading:false,
      loadingDtatus:false,
      invoicePositions: [],
      invoiceCustomerData: [],
      shippingData: [],
      items: [],
      invoiceNo: null,
      orderNo: null,
      saleTimeStamp: null,
      shippingType: null,
      invoiceTotal: 0.00,
      invoiceTotalNet: 0.00,
      invoiceTotalTax: 0.00,
      deliveryTimeStamp: 1,
    }
  },
  computed:{
    fields() {
      return [
        {
          key: "pos",
          sortable: false,
          label: 'Pos.'
        },
        {
          key: "descr",
          sortable: false,
          label: this.$t('generic.lang_item')
        },
        {
          key: "qty",
          sortable: false,
          label: this.$t('erp.lang_QTY')
        },
        {
          key: "unitPrice",
          sortable: false,
          label: this.$t('erp.lang_ware_UnitPrice'),
          headerTitle: this.$t('generic.lang_ware_UnitPrice')
        },
        {
          key: "total",
          sortable: false,
          label: this.$t('generic.lang_total')
        }
      ]
    },
    computedInvoicePositions() {
      let items = this._.cloneDeep(this.items);

      return items.map((item) => {
        item.total = this.$options.filters.currency(item.total);
        item.unitPrice = this.$options.filters.currency(item.unitPrice);
        return item;
      });
    },
  },
  methods:{
    getDate(timestamp){
      return moment.unix(timestamp).format("HH:mm DD.MM.YY")
    },
    displayEntry(){
      this.dialog = true;
      this.loading = true;

      this.axios.post(ENDPOINTS.ACCOUNTING.DISPLAYINVOICE.GET, {
        invoiceID: this.uuid,
      }).then(res => {
        if (res.data.success) {
          this.orderNo = res.data.data.invoiceData.invoiceSaleID;
          this.deliveryTimeStamp = res.data.data.invoiceData.deliveryTimeStamp;
          this.invoiceNo = "" + res.data.data.invoiceData.invoiceSaleRealm + res.data.data.invoiceData.invoiceSaleID + res.data.data.invoiceData.invoiceRandomizerID;
          this.saleTimeStamp = res.data.data.invoiceData.saleTimeStamp;
          this.invoiceTotalTax = res.data.data.invoiceData.totalTax;
          this.invoiceTotal = res.data.data.invoiceData.invoiceTotal;
          this.invoiceTotalNet = res.data.data.invoiceData.invoiceTotalNet;
          this.items = res.data.data.invoicePositionData;
          this.invoiceCustomerData = res.data.data.invoiceData.customer_data;
          this.shippingData = res.data.data.invoiceData.shippingData;
          this.shippingType = res.data.data.invoiceData.shippingType;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    setStatus(state){
      this.loadingDtatus = true;

      this.axios.post(ENDPOINTS.ONLINESHOP.ORDER.SETSTATUS, {
        invoiceUUID: this.uuid,
        state:state,
      }).then(res => {
        if (res.data.status==="SUCCESS") {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_success'),
            color: "success"
          });
          this.displayEntry();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingDtatus = false;
      })
    },
  },
  mounted() {
    this.displayEntry();
  }
}
</script>

<style scoped>

.invoice-box {
  background-color: #ffffff;
  max-width: 1200px;
  margin: auto;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.item input {
  padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
  margin-left: -5px;
  width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

.invoice-box input[type="number"] {
  width: 60px;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.rtl {
  direction: rtl;
  font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
  sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>