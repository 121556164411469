<template>
  <v-container fluid>
    <v-row class="pa-0">
      <v-col class="pa-0" cols="12">
        <v-form ref="form" lazy-validation>
          <v-row>

            <v-col cols="12" sm="6">
              <BaseDateInput v-model="startDateTime" :disabled="this.dates_loading"
                            :label="$t('accounting.lang_dailyReportStart')"
                            :loading="this.dates_loading"
                            :rules="[v => !!v]" dense outlined
                            type="date"/>
            </v-col>
            <v-col cols="12" sm="6">
              <BaseDateInput v-model="endDateTime" :disabled="this.dates_loading"
                            :label="$t('accounting.lang_dailyReportEnd')"
                            :loading="this.dates_loading"
                            :rules="[v => !!v]" dense outlined
                            type="date"/>
            </v-col>
            <v-col class="pt-0 pb-2" cols="12" md="2" sm="4">
              <v-btn block class="mx-auto elevation-0" color="primary" @click="filter()">
                <v-icon>filter_list</v-icon>
                {{ $t('generic.lang_filter') }}
              </v-btn>
            </v-col>

          </v-row>
        </v-form>
      </v-col>
      <v-col class="pa-0" cols="12">
        <v-card outlined>
          <Datatable v-if="showTable" ref="salesTable" :api-endpoint="ENDPOINTS.DATATABLES.ONLINESHOP.ORDERS"
                     :data="this.params"
                     :datatable-headers="datatableHeaders"
                     :excel-columns="excelColumns"
                     excel-file-name="Rechnungen"
                     show-display-buttons
                     @displayEntry="displayEntry"
                     pdf-f-ile-name="invoices"
                     show-pdf
                     show-selected-pdf
                     @downloadSelectedPdf="downloadSelected" :pdf-selected-loading="loadingSelectedPDF"
          >
            <template v-slot:item.total="{item}">
              {{ item.total | currency }}
            </template>
          </Datatable>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidth"/>
        </div>
      </v-col>
    </v-row>
    <OrderOverviewComponent v-if="orderDialog" :uuid="this.uuid" v-model="orderDialog" />
  </v-container>
</template>


<script>
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from '@/config'
import mixin from "../../mixins/KeyboardMixIns";
import DateTimePicker from "@/components/common/datetimepicker";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';
import OrderOverviewComponent from "./OrderOverviewComponent";
import BaseDateInput from "@/components/common/BaseDateInput.vue";

export default {
  components: {
    OrderOverviewComponent,
    Datatable,
    DateTimePicker,
    BaseDateInput
  },

  mixins: [mixin],

  data() {
    return {
      orderDialog:false,
      dates_loading: false,
      startDateTime: "",
      endDateTime: "",
      dailyReportID: "",
      ENDPOINTS,
      reports_loading: false,
      customer_department: null,
      datatableHeaders: [
        {
          text: this.$t('generic.lang_id'),
          align: 'left',
          value: "id",
          hide: true
        },
        {text: this.$t('generic.lang_id'), value: "ident", sort: "desc"},
        {text: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), value: "date"},
        {text: this.$t('generic.lang_seller_id'), value: "sellerid"},
        {text: this.$t('generic.lang_customer'), value: "cust"},
        {text: this.$t('erp.lang_totalsolditems'), value: "saleItems"},
        {text: this.$t('erp.lang_ware_total'), value: "total"},
        {text: this.$t('accounting.lang_paymenthod'), value: "paymentType"},
        {text: this.$t('generic.lang_TableNo'), value: "tableNo", hide: true},
        {text: this.$t('generic.lang_parMaison'), value: "parMaison"},
        {text: this.$t('generic.lang_cashierID'), value: "cashierID"},
        {text: this.$t('generic.lang_receipttype'), value: "type"},
      ],
      excelColumns: [
        {label: this.$t('generic.lang_id'), field: "ident"},
        {label: this.$t('generic.lang_time') + " / " + this.$t('generic.lang_date'), field: "date"},
        {label: this.$t('generic.lang_seller_id'), field: "sellerid"},
        {label: this.$t('generic.lang_customer'), field: "cust"},
        {label: this.$t('erp.lang_totalsolditems'), field: "saleItems"},
        {label: this.$t('erp.lang_ware_total'), field: "total"},
        {label: this.$t('accounting.lang_paymenthod'), field: "paymentType"},
        {label: this.$t('generic.lang_TableNo'), field: "tableNo"},
        {label: this.$t('generic.lang_parMaison'), field: "parMaison"},
        {label: this.$t('generic.lang_cashierID'), field: "cashierID"},
        {label: this.$t('generic.lang_receipttype'), field: "type"},
      ],
      id: null,
      uuid: null,
      customerIdAdd: null,
      addSearch: null,
      items: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      showUpdate: false,
      tab: 0,
      dataTable: null,
      loadingSelectedPDF:false,
      pdfSelectLimit:15, // the max number of selected invoices to export
    }
  },
  mounted() {
    //this.loadData();
  },
  watch: {
    startDateTime(val) {
      if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() >= new Date(val).getTime()) {
        //this.$refs.salesTable.getDataFromApi();
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
        this.startDateTime = "";
      }
    },
    endDateTime(val) {
      if (this.startDateTime != "" && this.startDateTime != null && new Date(this.startDateTime).getTime() <= new Date(val).getTime()) {
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
        this.endDateTime = "";
      }
    },
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
        this.showUpdate = false;
      }
    }
  },
  computed: {
    params: function () {
      return {
        start_date: this.startDateTime === "" ? "" : new Date(this.startDateTime).getTime() / 1000,
        end_date: this.endDateTime === "" ? "" : new Date(this.endDateTime).getTime() / 1000,
        daily_report_id: this.dailyReportID,
        department: this.customer_department
      }

    }
  }
  ,
  methods: {
    filter() {
      //if (!this.$refs.form.validate()) return;
      this.$refs.salesTable.getDataFromApi();
    },
    displayEntry(entry) {
      this.uuid = entry.id;
      this.orderDialog=true;
    },
    resetData: function () {
      this.showCreate = false;
      this.showUpdate = false;
      this.showTable = true;
      this.id = null;
      this.text = "";
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },
    downloadSelected(ids){
      if(ids && ids.length>this.pdfSelectLimit){
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_downloadLimit')+' '+this.pdfSelectLimit+' '+this.$t('generic.lang_selected')+' '+ids.length,
          color: "warning"
        });
        this.$refs.salesTable.resetSelectedRows();
        return;
      }
      this.loadingSelectedPDF = true;
      this.axios.post("get/accounting/bulkInvoiceDataPDF/", {
            invoiceUUIDS:ids
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), "Invoice" + ".pdf");
        this.$refs.salesTable.resetSelectedRows();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingSelectedPDF = false;
      })
    },
  },
}
</script>
