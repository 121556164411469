<template>
  <div>
    <page-title :heading="$t('generic.lang_online_shop_orders')" :subheading="$t('generic.lang_online_shop_orders')" :icon=icon></page-title>
    <div class="app-main__inner">
      <OnlineShopOrdersComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import OnlineShopOrdersComponent from "../../components/onlineshop/OnlineShopOrdersComponent";

export default {
  components: {
    OnlineShopOrdersComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-shopbag icon-gradient bg-tempting-azure',
  })
}
</script>